export enum SYNC_CHAT_DELAY_LINE_NOTIFY {
    NONE = 'NONE',
    ONE_MINUTE = 'ONE_MINUTE',
    THREE_MINUTE = 'THREE_MINUTE',
    FIVE_MINUTE = 'FIVE_MINUTE',
}

export const delayLineNotifyOptions = [
	// {
	// 	name: 'ทุกข้อความ',
	// 	value: SYNC_CHAT_DELAY_LINE_NOTIFY.NONE
	// },
	{
		name: 'ข้อความค้าง 1 นาที',
		value: SYNC_CHAT_DELAY_LINE_NOTIFY.ONE_MINUTE
	},
	{
		name: 'ข้อความค้าง 3 นาที',
		value: SYNC_CHAT_DELAY_LINE_NOTIFY.THREE_MINUTE
	},
	{
		name: 'ข้อความค้าง 5 นาที',
		value: SYNC_CHAT_DELAY_LINE_NOTIFY.FIVE_MINUTE
	},
]
